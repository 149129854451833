import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useContainer } from 'unstated-next'
import { CurrentUserState } from '../../../state/CurrentUserState'
import useData from './useData'

export const USER_TYPES = [
  { label: 'Parent or Guardian', value: 'parent' },
  { label: 'Teacher or Tutor', value: 'teacher' },
  { label: 'Other', value: 'other' },
]

const useRegisterPageState = () => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  const { createAccount } = useData()
  const { login } = useContainer(CurrentUserState)

  const onSubmit = async (
    firstName,
    lastName,
    email,
    password,
    userType,
    countryCode
  ) => {
    const name = `${firstName} ${lastName}`
    const redirectTo = urlParams.get('redirect')
    const { _id, sharedSecretKey } = await createAccount(
      name,
      email.toLowerCase(),
      password,
      userType,
      countryCode
    )
    if (/\/invite\/([a-z]|[0-9]){24}/.test(redirectTo || '')) {
      await login(_id, sharedSecretKey)
      const redirectTo = `${urlParams.get('redirect')}?${urlParams.toString()}`
      if (redirectTo) {
        navigate(redirectTo)
      }
    }
  }

  return { onSubmit }
}

export default useRegisterPageState
