import { useApolloClient } from '@apollo/client'
import CREATE_ACCOUNT from './graphql/createAccount.graphql'
import FREE_EMAIL from './graphql/freeEmail.graphql'

const useData = () => {
  const client = useApolloClient()

  const createAccount = async (
    name,
    email,
    password,
    userType,
    countryCode
  ) => {
    const { data } = await client.mutate({
      mutation: CREATE_ACCOUNT,
      variables: {
        input: { name, email, password, userType, countryCode },
      },
    })
    return data.createAccount
  }

  const freeEmail = async (email) => {
    const { data } = await client.query({
      query: FREE_EMAIL,
      variables: {
        email,
      },
    })
    return data.freeEmail
  }

  return { createAccount, freeEmail }
}

export default useData
