import { useLocation } from '@reach/router'
import CountrySelector from 'components/UI/CountrySelector'
import { CheckBox } from 'grommet'
import useAmplitude from 'hooks/useAmplitude'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import Input from '../../UI/Input'
import useData from './useData'
import useRegisterPageState, { USER_TYPES } from './useRegisterPageState'

const Wrapper = styled.div`
  padding-top: 50px;
  p {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Libre Baskerville';
    margin: 0;
  }
`

const Content = styled.div`
  border-radius: 20px;
  max-width: 350px;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #341644;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :last-child {
    margin-top: 10px;
  }
`

const Title = styled.div`
  color: #fff;
  padding: 0 20px;
  font-size: 13px;
  > :nth-child(2) {
    line-height: 1.5;
    margin-bottom: 15px;
  }
  > :first-child {
    font-family: 'Rammetto One';
    font-size: 21px;
    text-align: center;
    margin-bottom: 12px;
  }
`

const Form = styled.div`
  color: #fff;
  background-color: #ffffff11;
  padding: 20px 20px;
  font-family: 'Rammetto One';
  width: 100%;
  > * {
    margin-bottom: 13px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Group = styled.div`
  margin-bottom: 50px !important;
  > * {
    margin-bottom: 13px;
  }
`

const Field = styled.div`
  .error {
    color: #de1323;
    font-family: 'Libre Baskerville';
  }
  color: #222;
  > :first-child {
    color: #fff;
    position: relative;
    display: inline-flex;
  }
`

const Tip = styled.div`
  position: absolute;
  left: 100%;
  font-family: 'Libre Baskerville';
  top: 0;
  width: 100%;
  font-size: 12px;
  width: 201px;
  padding-left: 20px;
`

const TermsAndConditions = styled.div`
  .error {
    color: #de1323;
    font-family: 'Libre Baskerville';
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 15px;
  }
  > :last-child {
    > :first-child {
      margin-right: 10px;
    }
    display: flex;
    align-items: flex-start;
    a {
      color: inherit;
    }
  }
`

const VerifiedContent = styled.div`
  color: #fff;
  padding: 0 20px;
  p {
    margin-bottom: 15px;
  }
`

// const Links = styled.div`
//   text-align: center;
//   text-decoration: underline;
//   > * { cursor: pointer; }
// `

const RegisterPage = () => {
  const { onSubmit } = useRegisterPageState()
  const { freeEmail } = useData()
  const { logEvent } = useAmplitude()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: urlParams.get('email') || '',
    password: '',
    confirmPassword: '',
    userType: 'parent',
    countryCode: 'GB',
    termsAccepted: false,
  })
  const [verification, setVerification] = useState(false)
  const [errors, setErrors] = useState<any>({})

  useEffect(() => {
    logEvent('Dashboard: Register Follower Page Loaded')
  }, [])

  const updateField = (payload) => {
    setFormData((f) => ({
      ...f,
      ...payload,
    }))
  }

  const validatePassword = (password) => {
    return password.length >= 6
  }

  const validateEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const setError = (payload) => {
    setErrors((errors) => {
      const newErrors = { ...errors, ...payload }
      return newErrors
    })
  }

  const checkFirstName = () => {
    if (formData.firstName === '') {
      setError({ firstName: 'You need to provide a first name.' })
      return
    }
    setError({ firstName: null })
  }

  const checkLastName = () => {
    if (formData.lastName === '') {
      setError({ lastName: 'You need to provide a last name.' })
      return
    }
    setError({ lastName: null })
  }

  const checkEmail = async () => {
    if (!validateEmail(formData.email)) {
      setError({ email: 'Please enter a valid email address.' })
      return
    }
    const isEmailFree = await freeEmail(formData.email)
    if (!isEmailFree) {
      setError({ email: 'Email already taken.' })
      return
    }
    setError({ email: null })
  }

  const checkPassword = () => {
    if (!validatePassword(formData.password)) {
      setError({ password: 'Password must be at least 6 characters long.' })
      return
    }
    setError({ password: null })
  }

  const checkConfirmPassword = () => {
    if (formData.password !== formData.confirmPassword) {
      setError({ confirmPassword: "Passwords don't match." })
      return
    }
    setError({ confirmPassword: null })
  }

  const canSubmit = useMemo(() => {
    return (
      formData.firstName !== '' &&
      formData.lastName !== '' &&
      validateEmail(formData.email) &&
      validatePassword(formData.password) &&
      formData.password === formData.confirmPassword
    )
  }, [formData])

  const submit = async () => {
    if (!formData.termsAccepted) {
      setError({ termsAccepted: 'You must accept our terms and conditions.' })
      return
    }
    if (!canSubmit) return
    await onSubmit(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.password,
      formData.userType,
      formData.countryCode
    )
    logEvent('Dashboard: Register Follower Submitted')
    setVerification(true)
  }

  if (verification) {
    return <Wrapper>
      <Content>
        <Title>
          <div>Check your Email</div>
        </Title>
        <VerifiedContent>
          <p>We have sent you an email to confirm your email address.</p>
          <p>Select the link in the email to activate your account and continue.</p>
          <p>You will then be able to add the child you wish to follow.</p>
          <p>You can now safely close this window.</p>
          { /* <Links>
            <div>Resend email</div>
            <div>Back</div>
          </Links> */ }
        </VerifiedContent>
      </Content>
    </Wrapper>
  }

  return (
    <Wrapper>
      <Content>
        <Title>
          <div>Create an account</div>
          <div>
            Set up a free adult account on Night Zookeeper and link to a child's
            account to see their work and monitor their progress.
          </div>
        </Title>
        <Form>
          <Group>
          <Field>
            <div>First name</div>
            <div className="error">{errors.firstName}</div>
            <Input
              placeholder="e.g. Will"
              value={formData.firstName}
              onChange={(e) => updateField({ firstName: e.target.value })}
              onBlur={checkFirstName}
            />
          </Field>
          <Field>
            <div>Last name</div>
            <div className="error">{errors.lastName}</div>
            <Input
              placeholder="e.g. Rivers"
              value={formData.lastName}
              onChange={(e) => updateField({ lastName: e.target.value })}
              onBlur={checkLastName}
            />
          </Field>
          <Field>
            <div>User type:</div>
            <div className="error">{errors.userType}</div>
            <Select
              options={USER_TYPES}
              value={USER_TYPES.filter((t) => t.value === formData.userType)[0]}
              onChange={({ value }) => {
                updateField({ userType: value })
              }}
            />
          </Field>
          </Group>
          <Group>
          <Field>
            <div>Email</div>
            <div className="error">{errors.email}</div>
            <Input
              placeholder="e.g. will@nightzookeeper.com"
              value={formData.email}
              onChange={(e) => updateField({ email: e.target.value })}
              onBlur={checkEmail}
            />
          </Field>
          <Field>
            <div>Country:</div>
            <div className="error">{errors.countryCode}</div>
            <CountrySelector
              value={formData.countryCode}
              onChange={(countryCode) => updateField({ countryCode })}
              countryCodes={['GB', 'US', 'CA', 'AU']}
            />
          </Field>
          </Group>

          <Group>
          <Field>
            <div>
              Password
              <Tip>(6 characters minimum)</Tip>
            </div>
            <div className="error">{errors.password}</div>
            <Input
              placeholder="e.g. *******"
              type="password"
              value={formData.password}
              onChange={(e) => updateField({ password: e.target.value })}
              onBlur={checkPassword}
            />
          </Field>
          <Field>
            <div>Confirm password</div>
            <div className="error">{errors.confirmPassword}</div>
            <Input
              placeholder="e.g. *******"
              type="password"
              value={formData.confirmPassword}
              onChange={(e) => updateField({ confirmPassword: e.target.value })}
              onBlur={checkConfirmPassword}
            />
          </Field>
          </Group>

          <TermsAndConditions>
            <div className="error">{errors.termsAccepted}</div>
            <div>
              <CheckBox
                checked={formData.termsAccepted}
                onChange={(e) =>
                  updateField({ termsAccepted: e.target.checked })
                }
              />
              <p>
                I am over 18 and I agree to the following{' '}
                <a
                  href="https://nightzookeeper.com/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a
                  href="https://nightzookeeper.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </TermsAndConditions>
          <p>
            In creating this account, I give Night Zookeeper permission to
            contact me about their products and services.
          </p>
        </Form>
        <Button
          theme="purple"
          size="regular"
          disabled={!canSubmit}
          onClick={submit}
        >
          Submit
        </Button>
      </Content>
    </Wrapper>
  )
}

export default RegisterPage
