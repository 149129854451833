import React from 'react'
import RegisterPage from '../components/pages/RegisterPage'
import UnauthenticatedLayout from '../layouts/UnauthenticatedLayout'

const Register = () => {
  if (typeof window === 'undefined') return null

  return (
    <UnauthenticatedLayout>
      <RegisterPage />
    </UnauthenticatedLayout>
  )
}

export default Register
